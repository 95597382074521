import React from 'react'
import {NavLink} from 'react-router-dom';
import {UserDataContext} from '../containers/LoginContext';


export default function Header(props){
  //in questo modo ci prendiamo solo lo user, setuser non ci interessa
  const [user] = React.useContext(UserDataContext);

  return (
    <header className="App-header">
      <nav>
        <ul className="menu">
          {
            user ? 
            <>
              <li>
              <NavLink exact activeStyle={{backgroundColor:'red'}} to="/">My TODO list</NavLink>
              </li>
              <li><NavLink activeClassName="active" to="/lists">Lists</NavLink></li>
              <li><NavLink activeClassName="active" to="/todos">Todos</NavLink></li>
            </> : null
          }
          
          {
            user ? 
              <>
                <li>WELCOME {user.name}</li>
                <li><NavLink to='/logout'>LOGOUT</NavLink></li>
              </> : (
              <>
                <li><NavLink activeClassName="active" to="/signup">Sign up</NavLink></li>
                <li><NavLink activeClassName="active" to="/login">Login</NavLink></li>
              </>
            )
          }
          
        </ul>
      </nav>
    </header>

  )
}

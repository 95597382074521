import {connect} from 'react-redux'
import AddList from '../components/AddList'
import {addList} from '../actions/list_actions.js'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addNewList: (list) => {
      dispatch(addList(list))
    }
  }
}

//connect può anche non ricevere parametri, ma cmq collega il componente allo store
// passando in automatico il dispatch
// primo parametro di connect è la mapStateToProps che modifica lo stato
// secondo parametro è la mapDispatchToProps che va a indicare le funzioni che modificheranno lo stato
const ContAddList = connect(null, mapDispatchToProps)(AddList)

export default ContAddList
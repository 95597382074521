import React, { Component } from "react";


class ErrorBoundary extends Component {
    
    /*Il componente che gestisce l'errore deve essere una classe
    Nel costruttore definiamo lo stato iniziale di questa classe con due proprietà
    hasError booleano e il messaggio volendo. */
    constructor(props){
        super(props)
        this.state = {
            hasError: false,
            errorMessage: ''
        }
    }
    
    /* Viene chiamata quando l'errore è avvenuto 
    Restituisce lo stato currente al momento dell'errore
    Possiamo decidere noi come restituirlo*/
    static getDerivedStateFromError(error){
        return {
            hasError: true,
            errorMessage: error
        }
    }
    /** Questa funzione viene eseguita quando l'errore e la componente sono stati
     * gà montati e quindi dopo il render
     */
    componentDidCatch(error, info){
        //console.error(error, info);
    }


    render(){
        if(this.state.hasError){
            return  <h1> An Error Occured</h1>
        }
        return this.props.children;
    }
}

export default ErrorBoundary
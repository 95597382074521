
import {APIURL, API_FILTER_URL} from '../config/config'
import axios from 'axios';
import {TODOS, ADD_TODO, REMOVE_TODO, TOGGLE_TODO, SET_FILTER} from './actiontypes';




export const getTodos = (list=10, filter='ALL') =>{
  console.log('GET_TODOS_ACTION:', APIURL + '?list_id=' + list)
  return {
    type: TODOS,
    payload: axios.get(APIURL, 
      {
        params:{
          list_id:list,
          filter:filter
        }
      }
    )
  }
}

export const addTodo = (todo, list=0) =>{
  // const todo = this.todoInput.current.value
  // // con dispatch viene chiamato il reducer passato in createStore
  // // dispatch passa un oggetto al reducer che va a finire nel parametro action
  // store.dispatch({
  //   type: 'ADD_TODO', //type è obbligatorio
  //   todo: todo
  // })
  // attravero la connect la dipsatch non serve più perchè lo fa in automatico redux
  return {
    type: ADD_TODO,
    payload: axios.post(APIURL, {
      todo:todo,
      list_id: +list, //facciamo il cast ad un intero perche l'id viene salvato come stringa
      completed: false
    })
  }
}
// questi sono action creator
export const removeTodo = (i) => {
  // store.dispatch({
  //   type: 'REMOVE_TODO',
  //   id: i
  // })

  return {
    type: REMOVE_TODO,
    payload: axios.delete(APIURL + '/' + i, {'id': i}), // il secondo parametro sono dati chhe possiamo aggiungere al payload.config 
    id: i
  }
}

export const toggleTodo = (todo, value) => {
  
  return {
    type: TOGGLE_TODO,
    payload: axios.patch(APIURL + '/' + todo.id, {
      ...todo,
      completed: value
    })
  }
}

export const filterTodo = (filter = 'ALL') => {

  return {
    type: SET_FILTER,
    payload: axios.post(API_FILTER_URL, {
      filter: filter
    }),
  }

}

import React from 'react'
import ListItem from './ListItem'

export default function Lists(props){
    
    return (<ul className="lists">
        {
            props.lists.map(list => <ListItem key={list.id} id={list.id} 
             item={list} removeList={props.removeList}/>)
        }
    </ul>

    )
}
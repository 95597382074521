import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import Auth from '../auth/auth';
import {UserDataContext} from './LoginContext';

export default function PrivateRoute(props){
    const [user] = React.useContext(UserDataContext);

    if (user){
        return (
            <Route {...props} />
        );
    }
    else {
        return (
            <Redirect to = {
                {
                    pathname: '/login',
                    state: {
                        from: props.location.pathname
                    }
                }
            } />
        );
    }
    
}
import React from 'react'
import {Link} from 'react-router-dom'


export default function List(props){
  
    return (
        <li key={props.item.id}>
          <Link to={{
                pathname:'/lists/' + props.item.id + '/todos',
                state:{listName: props.item.name}
              }

            }>
            {props.item.name}
            <span className='cross' onClick ={(e) =>{
              e.stopPropagation(); 
              props.removeList(props.item.id)
            } }></span>
          </Link>
          
        </li>
      );
}
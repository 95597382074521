import ToDoFooter from '../components/ToDoFooter'
import {connect} from 'react-redux'
import {filterTodo} from '../actions/index.js'
import {withRouter} from 'react-router-dom';
// la connect senza parametri restituisce una dispatch

const mapStateToProps = (state, ownProps) => {

    return {
        activeFilter: state.setFilter
    }
}

//withRouter fa accedere al router il componente ToDoFooter
const ContToDoFooter = withRouter(connect(mapStateToProps, {filterTodo})(ToDoFooter));

export default ContToDoFooter


/*export const APIURL = 'http://localhost:3004/todos';
export const APIURL_LIST = 'http://localhost:3004/lists';

export const API_FILTER_URL = 'http://localhost:3004/activeFilter';*/

const LARAVEL_API = 'http://ancient-crag-75281.herokuapp.com/'
export const APIURL = LARAVEL_API + 'todos';
export const APIURL_LIST = LARAVEL_API + 'todolists';

export const API_FILTER_URL = LARAVEL_API + 'activeFilter';

export const AUTH_URL = LARAVEL_API + 'api/auth/';


import {APIURL_LIST} from '../config/config'
import axios from 'axios'
import {LISTS, ADD_LIST, REMOVE_LIST} from './actiontypes';


export const addList = (list) => {
    return {
      type: ADD_LIST,
      payload: axios.post(APIURL_LIST, {
        name: list
      })
    }
  }
  
  export const getLists = () => {
    return {
      type: LISTS,
      payload: axios.get(APIURL_LIST)
    }
  }
  
  export const removeList = (i) => {
    return {
      type: REMOVE_LIST,
      payload: axios.delete(APIURL_LIST + '/' + i, {'id': i}), // il secondo parametro sono dati chhe possiamo aggiungere al payload.config 
      id: i
    }
  }
export const TODOS = 'TODOS'
export const ADD_TODO = 'ADD NEW TODO'
export const REMOVE_TODO = 'REMOVE TODO FROM LIST'
export const TOGGLE_TODO = 'CHANGE TOTO FROM NOT COMPLETED TO COMPLETED'
export const SET_FILTER = 'FILTERS TODO ACCORDING TO STATE'

/* LISTS ACTIONS */
export const ADD_LIST = 'ADD LIST';
export const LISTS = 'GET LISTS';
export const REMOVE_LIST = 'REMOVE LIST';

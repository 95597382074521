import React, { Component } from 'react';
import ToDo from './ToDo'
import PropTypes from 'prop-types'

class TodoList extends Component{
  constructor(props){
    super(props);
    if(this.props.error.hasError)
    {
      // qui dentro possiamo decidere se mostrare un componente diverso oppure
      // lasciare che sia l'ErrorBoundary a gestire la cosa e mostrare il componente giusto
      throw new Error(props.errorMessage)
    }
  }

  componentDidMount(){
    this.props.getTodos(this.props.list, this.props.filter);
  }

  componentDidUpdate(prevProps){
    
    if(this.props.list !== prevProps.list || this.props.filter !== prevProps.filter){
      this.props.getTodos(this.props.list, this.props.filter)
    }
  }
  
  render(){
      return (<ul className="todos">
        {
          this.props.todos.map((todo, i) => <ToDo key={todo.id} id={todo.id} 
          setComplete = {this.props.toggleTodo} todoItem={todo} removeTodo = {this.props.removeTodo}/>)
        }
      </ul>

      )
  }
}

TodoList.propTypes = {
  props: PropTypes.shape({
    hasError: PropTypes.bool,
    todos: PropTypes.array,
    activeFilter: PropTypes.string.isRequired,
    onClickFilter: PropTypes.func.isRequired,
    actionType: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired

  })}

export default TodoList

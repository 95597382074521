import {connect} from 'react-redux'
import Lists from '../components/Lists'
import {removeList} from '../actions/list_actions'
const mapStateToProps = (state, ownProps) => {
    return {
      lists: state.lists,
    }
  }


const ContLists = connect(mapStateToProps, {removeList})(Lists)


export default ContLists
import {TODOS, ADD_TODO, REMOVE_TODO, TOGGLE_TODO} from '../actions/actiontypes';

/**
 * Dato che ora lo stato viene gestito nelle sue singole parti ora le diverse action devono restituire degli oggetti
 * che vanno a riempire le singole parti dello stato. Perchè in index.js abbiamo definito un combine reducer che
 * indica che per la parte dei todos viene gestita da questo reducer quindi questo reducer deve restituire un
 * array di todos. In questo caso state è già un array di todos
 */
export default function todosReducer(state={}, action){
  switch (action.type) {
    case TODOS + '_FULFILLED':{
      const list = +action.payload.config.list
      const todos = action.payload.data.result.data;
      const success = action.payload.data.success;
      return todos;

    }
      
    case ADD_TODO + '_FULFILLED':{
      // quando si fa l'addtodo il server non risponde con un paginate
      // quindi non c'è il campo data in result
      return [action.payload.data.result, ...state]

    }
      
    case REMOVE_TODO + '_FULFILLED':{
        const success = action.payload.data.success;
        return state.filter(todo => success && todo.id !== action.payload.config.id)
      }
      

    case TOGGLE_TODO + '_FULFILLED':
      {
        const result = action.payload.data.result;

        return state.map((todo) => {
            if (todo.id !== result.id) {
                return todo
            }
            return result;
        });
        /*return state.map((todo) => {
          if(todo.id !== action.payload.data.id){
            return todo
          }
          return result;
        });*/
      }
      
    default:
      return state; //spread di un oggetto

  }

}

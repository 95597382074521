import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {applyMiddleware, createStore, compose} from 'redux'
import storeReducer from './reducers/index.js'
import {logger} from 'redux-logger';
import promise from 'redux-promise-middleware'
import { BrowserRouter } from 'react-router-dom'
let storeTodo = {
    lists:[],
    todos:[],
    setFilter: '',
    error:{
      hasError: false,
      errorMessage: ''
    }
  }



/**
 * le componenti raggiunte tramite la componente Route ricevono un oggetto
 * contentente l'oggetto match, infatti lo prrendiamo attraverso la destrutturazione in questo caso.
 * L'oggetto match contiene diverse informazioni utili alla componente destinataria di Route.
 * Ad esempio possiamo accedere ai parametri attraverso l'attributo .params di match
 * Da notare come i parametri vengono indicati, /:<nome_parametro>.
 */
/*const Test = ({match}) => {
  console.log(match);
  return (
    <React.Fragment>
      <Route path={match.path + '/:id'} component={Test2}></Route>
      <div> Test component</div>
    </React.Fragment>
    
  )
};*/

//secondo parametro è lo stato iniziale
// createStore prende la funzione reducers e come secondo parametro lo stato da passare
if(localStorage.getItem('mytodolist'))
{
  const currState = JSON.parse(localStorage.getItem('mytodolist'))
  if(currState && !currState.error.hasError){
    storeTodo = currState
  }
}
// questa funzione verrà data all'ApplyMiddleware che passa a logger 
// un oggetto contenente il getState e la dipatch
// ApplyMiddleware prende in input una serie di middleware, ovvero di funzioni
// che si interpongono tra le dispatch, catturano lo stato e possono modificarlo
// prima che la successiva dispatch possa essere eseguita
// i middleware sono utili per le chiamata asincrone
function esempioMiddleware(store) {
  // la prima funzione restituisce una dispatch che verrò eseguita
  return function(next){
    // questa è una funzione dispatch che potrebbe modificare i dati ricevuti prima
    // di darli alla successiva dispatch
    return function(action){
      // qui va tutta la logica prima che venga chiamato il successivo dispatch
      let result = next(action); // chiama il successivo middleware con i dati che passiamo
      return result // il successivo middleware può ricevere questo risultato
    }

  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(storeReducer, {...storeTodo}, 
  composeEnhancers(applyMiddleware(logger, promise)));

// ogni volta che cambia stato noi lo andiamo a memorizzarlo nel local storage
store.subscribe( () =>{
  const state = store.getState()
  // evitiamo di immagazzinare i dati se ci sono errori
  if(!state.error.hasError){
    const currState = JSON.stringify(state)
    localStorage.setItem('mytodolist', currState);
  }
  

});
/* La componente BrowserRouter gestirà il routing dell'App
  Per questo deve avvolgere l'intera app ma deve stare
  dentro Provider per accedere comunque allo store
*/
ReactDOM.render(<Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {useState} from 'react'; //utilizziamo gli hooks

import Auth from '../auth/auth';
import {UserDataContext} from '../containers/LoginContext';
const Login = (props) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = React.useContext(UserDataContext);

    const loginUser = (e) => {
        //alert(email + ' ' + password)
        e.preventDefault();

        Auth.signin(email, password).then(payload => {
            setUser(payload.user);
            props.history.push('/');
        }).catch(error =>{
            alert(error)
        });

    }

    const signupUser = (e) => {
        //alert(email + ' ' + password)
        e.preventDefault();

        Auth.signup(email, name, password).then(payload => {
            setUser(payload.user);
            props.history.push('/');
        }).catch(error =>{
            alert(error)
        });

    }

    const resetForm = (e) => {
        setPassword('');
        setEmail('');
    }
    
    return (
        <form className='form login' onSubmit={props.signup ? signupUser:loginUser}>
            <h1>{props.signup ? 'SIGNUP': 'LOGIN'}</h1>
            {props.signup && <div className='form-group'>
                <label htmlFor="name">Username</label>
                <input required name='name' id='name' className='form-field' 
                onChange={(e) => setName(e.target.value)}/>
            </div>}
            <div className='form-group'>
                <label htmlFor="email">Email</label>
                <input required name='email' id='email' className='form-field' 
                onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className='form-group'>
                <label htmlFor="password">Password</label>
                <input required name='password' id='password' className='form-field'
                onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className='form-group buttons'>
                <button className='btn btn-success'>{props.signup ? 'SIGNUP' : 'LOGIN'}</button>
                <button type="reset" className='btn btn-success'
                onClick={resetForm}>RESET</button>
            </div>
        </form>
    );
}

export default Login;
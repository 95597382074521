import React from 'react';

import ContAddList from './ContAddList';
import ContLists from './ContLists';

export default function ContListsList(props){

    console.log(props);

    return (<div className='container'>
    <ContAddList />
    <ContLists></ContLists>
</div>)
}
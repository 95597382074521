import React, {Component} from 'react';
import Header from './components/Header';
import {getTodos} from './actions/index';
import {getLists} from './actions/list_actions';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import ContTodos from './containers/ContTodos';
import ContListsList from './containers/ContListsList';
import Login from './components/Login';
import './App.css';
import Signup from './components/Signup';
import PrivateRoute from './containers/PrivateRoute';
import Logout from './components/Logout';
import {UserDataProvider} from './containers/LoginContext';

class App extends Component {
  // constructor(){
  //   super();
  //   // this.state = {
  //   //   todos: []
  //   // }
  //
  //   //this.todoInput = React.createRef();//riferimento al tag input
  // }

  // utilizziamo questo metodo per prendere tutti i dati dal server non appena l'app è stata aperta
  componentDidMount() {
    //this.props.getTodos()
    this.props.getLists();
  
    // this.setState({
    //   todos:store.getState().todos,
    // })
    //
    // // ci abboniamo allo store e restiamo in ascolto di tutti i cambiamenti
    // // che avvengono nello store e in questo caso aggiorniamo lo stato con i
    // // nuovi valori
    // store.subscribe(() => {
    //   console.log(store.getState());
    //   this.setState({
    //     todos:store.getState().todos,
    //   })
    // })
  }


  /**
   * Per gestire gli errori bisogna creare una componente che fa quel lavoro
   * e poi avvolgere il componente che pensiamo possa lanciare quell'errore
   * oppure avvolgere l'intera app
   */
  render(){
    return (
      <UserDataProvider>
        <div className="App">
          <Header />
          <Switch>
            <PrivateRoute path="(/|/todos)" component={ContTodos}/>
            <PrivateRoute path="/lists/:id([0-9]+)/todos"  component={ContTodos}/>
            <PrivateRoute path="/lists" component={ContListsList}/>
            <Route path="/login" component={Login} /> 
            <Route path="/signup" component={Signup} />
            <Route path="/logout" component={Logout} /> 
          </Switch>
          
        </div>
      </UserDataProvider>
    );
  }

}

export default connect(null, {getTodos, getLists})(App);

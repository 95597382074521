import React from 'react'


// ora tutta la logica della dispatch avverrà nel container ContAddToDo
// la funzione riceverà in input una funzione che in automatico va a fare la
// dispatch
export default function AddToDo(props){
  console.log('ADD TODO:', props);
  let todoInput;
  let listId = props.list
  return (
    <div className='addtodo'>
      <input
        onKeyUp = {(event)=>{
          if(event.keyCode === 13){
            props.addNew(todoInput.value, listId)
            todoInput.value = ""
          }
        }}
        ref = {(node) => {todoInput = node} /*riferimento al nodo del DOM che gli passermento*/}/>
      <button onClick={() => {
          props.addNew(todoInput.value, listId)
          todoInput.value = ""
        }}>Add</button>
    </div>
  )
}

import React from 'react';
import Auth from '../auth/auth';
import { UserDataContext } from '../containers/LoginContext';

export default function Logout(props){
    const [user, setUser] = React.useContext(UserDataContext);

    React.useEffect(()=>{

        Auth.logout().then(()=>{
            setUser(null);
            props.history.push('/')
        })
    }, [])
    return null;
}
import React from 'react';
import PropTypes from 'prop-types';


export default function ToDo(props){
  return (
    <li className={props.todoItem.completed ? 'completed':''} 
    onClick={() => {props.setComplete(props.todoItem, !props.todoItem.completed)}}>
      <span className={props.todoItem.completed ? 'completed': 'uncomplete'}></span>
      {props.todoItem.todo}
      <span className='cross' onClick ={(e) =>{
        e.stopPropagation(); 
        props.removeTodo(props.id)
      } }></span>
    </li>
  );
}

ToDo.defaultProps = {
  id:0
}

/**
 * Utilizziamo questa tecnica per verificare che i parametri passati alle varie
 * componenti siano giusti
 */
ToDo.propTypes ={
  todoItem: PropTypes.shape({
    completed: PropTypes.number,
    todo: PropTypes.string,
    id: PropTypes.number
  }),
  setComplete: PropTypes.func.isRequired,
  id: PropTypes.number,
  removeTodo: PropTypes.func.isRequired
}
//<button onClick={() => {props.removeTask(props.id)}}> - </button>

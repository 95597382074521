import React from 'react'


// ora tutta la logica della dispatch avverrà nel container ContAddToDo
// la funzione riceverà in input una funzione che in automatico va a fare la
// dispatch
export default function AddList(props){
  console.log('ADD_LIST:', props)
  let addListInput;
  return (
    <div className='addtodo'>
      <input
        onKeyUp = {(event)=>{
          if(event.keyCode === 13){
            props.addNewList(addListInput.value)
            addListInput.value = ""
          }
        }}
        ref = {(node) => {addListInput = node} /*riferimento al nodo del DOM che gli passermento*/}/>
      <button onClick={() => {
          props.addNewList(addListInput.value)
          addListInput.value = ""
        }}>Add</button>
    </div>
  )
}
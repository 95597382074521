import {ADD_LIST, LISTS, REMOVE_LIST} from '../actions/actiontypes';

/**
 * Dato che ora lo stato viene gestito nelle sue singole parti ora le diverse action devono restituire degli oggetti
 * che vanno a riempire le singole parti dello stato. Perchè in index.js abbiamo definito un combine reducer che
 * indica che per la parte dei todos viene gestita da questo reducer quindi questo reducer deve restituire un
 * array di todos. In questo caso state è già un array di todos
 */
export default function listsReducer(state=[], action){
  switch (action.type) {
    case ADD_LIST + '_FULFILLED':
      return [
        action.payload.data.result, 
        ...state]
    case LISTS + '_FULFILLED':
      return action.payload.data.result.data;
    case REMOVE_LIST + '_FULFILLED':{
      const success = action.payload.data.success;
      if(!success){
        return state;
      }
      return state.filter(list => success && list.id !== action.payload.config.id)
    }
      
    default:
      return state; //spread di un oggetto

  }

}
import {TODOS, ADD_TODO, REMOVE_TODO, TOGGLE_TODO} from '../actions/actiontypes';

export default function errorReducer(state={}, action){
  switch (action.type) {
    // REJECTED ACTIONS
    // Qui gestiamo l'errore in cui le azioni sono rejected per qualche errore, ad esempio errore di rete
    // Nell'oggetto restituito, restituiamo un parametro hasError e il messaggio di errore
    // da ricordare che siamo noi a decidere i parametri che vogliamo restituire e come chiamarli
    case TODOS + '_REJECTED':
    case ADD_TODO + '_REJECTED':
    case REMOVE_TODO + '_REJECTED':
    case TOGGLE_TODO + '_REJECTED':
        // Non possiamo ritornare due oggetti diversi, per questo abbiamo incorporato dentro error gli altri due parametri
        // ogni reducer si occupa di una porzione dello stato!
      return {
        hasError: true,
        errorMessage: action.payload.message
      }
    // END REJECTED ACTIONS
    default:
      return {...state}; //spread di un oggetto

  }

}

import React from 'react'
import { NavLink } from 'react-router-dom'
import FilterLink from './FilterLink'


export default function ToDoFooter(props) {
  const todoUrl = props.match.url;
  console.log('ACTIVE FILTER:', props.filter);
  // se il filtro è ALL allora torna la stringa filter che contiene la stringa ALL altrimenti torna un NAVLINK
  const all = props.filter === 'ALL' ? props.filter :  <li className='active'>
    <NavLink to = {todoUrl + '?filter=ALL'}>ALL</NavLink></li>
  const todo = props.filter === 'TODO' ? props.filter : <li className='active'>
     <NavLink to = {todoUrl + '?filter=TODO'}>TODO</NavLink></li>
  const completed = props.filter === 'COMPLETED' ? props.filter :  <li className='active'>
    <NavLink to = {todoUrl + '?filter=COMPLETED'}>COMPLETED</NavLink> </li>

  return (
    <div className='footer'>
      <ul className='menu footer'>
          {all}
          {todo}
          {completed}
      </ul>
    </div>
  )
}

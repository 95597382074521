import todos from './todos_reducer';
import setFilter from './setfilter_reducer'
import error from './error_reducer'
import lists from './lists_reducer'

import {combineReducers} from 'redux'

/**
 * Il combineReducer combina i diversi reducer che si occupano di una singola parte dello stato
 * Infatti con questo codice stiamo dicendo che la parte relativa ai todos viene gestita dal reducer di nome todos
 * e cosi via.
 */
export default combineReducers({
  lists: lists,
  todos: todos,
  setFilter: setFilter,
  error: error
})
import React from "react"
import ContAddToDo from './ContAddToDo'
import ErrorBoundary from '../components/ErrorBoundary'
import ContTodoList from './ContTodoList'
import ContToDoFooter from './ContToDoFooter'
//import queryString from 'query-string'; questa libreria è ottima per fare il parsing dei parametri che passiamo dopo il ?

const parseFilter = (search)=>{
    if(search.indexOf('filter') === -1){
        return '';
    }
    const tokens = search.split('='); //nel caso in cui c'è solo un parametro
    return tokens[1];
}
export default function Todos({match, location}){
    console.log(location);
    console.log(match);
    const filterValue = parseFilter(location.search);
    const listId = match.params.id || 3;
    const listName = location.state ? location.state.listName : null;
    const listTitle = listName ? <h3>{listName}</h3>: null;
    return (<div className='container'>
            {listTitle}
            <ContAddToDo list={listId} />
            <ErrorBoundary>
                <ContTodoList filter={filterValue} todos={[]} list={listId}/>
            </ErrorBoundary>
            <ContToDoFooter filter={filterValue} />
        </div>)
}


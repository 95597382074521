import ToDoList from '../components/TodoList';
import {connect} from 'react-redux'
import {removeTodo, toggleTodo, getTodos} from '../actions/index.js'

const filterTodos = (todos = [], filter='ALL') => {
  switch (filter) {
    case 'TO_DO':
      return todos.filter(todo => !todo.completed)
    case 'COMPLETED':
      return todos.filter(todo => todo.completed)
    default:
      return todos

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,//serve per passare una copia e non l'originale
    todos: filterTodos(state.todos, state.setFilter)
  }
}

// connect prende in input una funzione che ritorna parte dello stato che ci interessa
// connect ritorna una funziona che prende in input il componente da connettere
// connect effettua l'abbonamento allo store in automatico
// ogni volta che cambia lo store verrà chiamata mapStateToProps
// il secondo argomento è la dispatch
const ContTodoList = connect(mapStateToProps, {removeTodo, toggleTodo, getTodos})(ToDoList)

export default ContTodoList

import {SET_FILTER} from '../actions/actiontypes';

export default function setFilterReducer(state={}, action){
  switch (action.type) {
    case SET_FILTER + '_FULFILLED':
      return action.payload.data.filter
    // END REJECTED ACTIONS
    default:
      return state; //spread di un oggetto

  }

}

import axios from 'axios';
import {AUTH_URL} from '../config/config';

function Auth(){
    const isTokenExpired = () => {
        const expires = +localStorage.getItem('token-expires');
        // se la data attuale è maggiore di quella salvata nella localstorage
        // allora il token è scaduto
        const res = (new Date()).getTime() > expires;
        if (res){
            localStorage.removeItem('token-expires');
            localStorage.removeItem('auth');
        }

        return res;

    }
    const handleError = (resp) => {
        let message = '';
        switch(+resp.status){
            
            case 401:
                message = resp.data.error;
                break;
            case 500:
                message = resp.data.message;
                break;
            default:
                message = 'Error contacting server';
                break

        }

        return message;

    };
    const addAxiosToken = () => {
        const token = getToken();
        if (token)
        {
            /* Se il token esiste allora lo andiamo ad aggiungere 
               Nell'header di ogni richiesta tramite la libreria axios
            */
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        }
    }
    const manageResult = (result) => {
            const data = result['data'];
            if (!data || !data['access_token']){
                return Promise.reject('invalid server response') ;
            }

            /* Prendiamo il tempo in millisecondi (getTime restituisce la quantita di millisecondi
                trascorsi tra il 1970 fino ad ora) poi lo andiamo a sommare alla quantita di expires in
                presente nel token ricevuto dal server convertito in millisecondi.
            */
            const expireData = (new Date()).getTime() + data['expires_in'] * 1000;
            // Salviamo l'expireDate nella localStorage
            localStorage.setItem('token-expires', expireData);
            localStorage.setItem('auth', JSON.stringify(result.data));

            return result.data;
    }
    const signin = async (email, password) => {
        try {
            const result = await axios.post( AUTH_URL + 'login',{
                    email, 
                    password 
                }
            );
            return manageResult(result);

        } catch( e ){
            console.log(e.response);
            return Promise.reject(handleError(e.response)) ;
        }



    };
    const signup = async (email, name, password) => {
        try {
            const result = await axios.post( AUTH_URL + 'signup',{
                    email, 
                    name,
                    password 
                }
            );
            return manageResult(result);

        } catch( e ){
            console.log(e.response);
            return Promise.reject(handleError(e.response)) ;
        }



    };
    const getUser = () => {
        if(isTokenExpired())
        {
            return null;
        }
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (auth){
            return auth.user;
        }
    }
    const getToken = () => {
        if(isTokenExpired())
        {
            return null;
        }
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (auth){
            return auth.access_token;
        }
    }
    const logout = async () => {
        // aggiungiamo il token all'header per far capire al server quale utente si sta sloggando
        addAxiosToken(); 
        try{
            const result = await axios.post(AUTH_URL + 'logout');
            localStorage.removeItem('token-expires');
            localStorage.removeItem('auth');//rimuoviamo il token
            return result;
        }catch( e ){
            console.log(e);
            return e ;
        }

    }
    const refresh = () => {

    }
    return {
        signin,
        signup,
        logout,
        refresh,
        getUser,
        isTokenExpired
    }
}

const authMethods = Auth();

export default authMethods